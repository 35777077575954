<template>
  <div class="bdc_main_wrap whero">
    <div class="thw_content">
      <div class="internal-sub-nav">
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="isn-item"
          :class="{ active: tab.id === selectedTab, disabled: tab.disabled }"
          @click="tabOptions.length === 1 ? null : onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </div>
    </div>
    <div
      v-if="tableauJwtToken && selectedTabHandlerType === 'tablueReportHandler'"
      class="bdc_top_panel_wrap"
    >
      <tableau-viz
        id="tableauViz"
        ref="tableauContainer"
        :src="tableauViewURL"
        :token="tableauJwtToken"
        :on-viz-load-error="handleTableauError"
        toolbar="top"
        hide-tabs
      >
        <custom-parameter name=":showShareOptions" value="false"></custom-parameter>
        <viz-filter field="Advertiser" :value="advertiserId"> </viz-filter>
        <viz-filter field="Start Date" :value="startDate"></viz-filter>
        <viz-filter field="End Date" :value="endDate"></viz-filter>
        <viz-filter field="Days To Convert Parameter" :value="updateConversionWindow"></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics' || mniTemplateId !== 17"
          field="Event Category"
          :value="eventFilters.eventCategory"
        ></viz-filter>
        <viz-filter
          v-if="
            (selectedTab !== 'journeyanalytics' || mniTemplateId !== 17) &&
              eventFilters.eventSubcategory != null
          "
          field="Event Subcategory"
          :value="eventFilters.eventSubcategory"
        ></viz-filter>

        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Media Type"
          :value="selectedMediaTypeOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Campaign ID"
          :value="campaignOptionsValues"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="AdReady ID"
          :value="selectedAudienceOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Creative ID"
          :value="creativeOptionsOptions"
        ></viz-filter>
        <viz-filter field=":refresh" value="yes"></viz-filter>
        <viz-parameter field="embed" value="yes"></viz-parameter>
      </tableau-viz>
      <!-- <tableau-viz id="tableauViz"></tableau-viz> -->
    </div>
    <div v-else-if="selectedTabHandlerType === 'incrementalityHandler'">
      <incrementality class="chart-sec" />
    </div>
    <div v-else-if="!tableauJwtToken && !loading">
      Something went wrong. Please contact Digital Remedy technical support.
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import axios from 'axios';
import { TableauViz, TableauEventType } from '@tableau/embedding-api';
import {
  MNI_REPORTING_TABLEAU_URL,
  DEFAULT_CONVERSION_WINDOW,
  MNI_OVERVIEW_TAB_OPTIONS,
  DEFAULT_MNI_ADVERTISER_TEMPLATE_MAPPING,
  TABLEAU_PROD_URL,
} from '~/constant';
import { formatDateForAPI } from '~/util/apiDateFormat';
import config from '~/config';
import { isDemoInstance, checkUserHasAiAssistant } from '~/util/utility-functions';
import EventBus from '../adready-vue/helpers/global/event-bus';

export default {
  components: {
    Incrementality: () =>
      import(
        /* webpackChunkName: "incrementality" */ '~/components/dash-panels/graphs/incrementality.vue'
      ),
  },
  data() {
    const tabOptions = MNI_OVERVIEW_TAB_OPTIONS;
    let selectedTab = '';
    let selectedTabObject = null;
    let selectedTabTitle = '';
    let selectedTabUrl = '';
    let selectedTabSubUrl = '';
    let selectedTabDataUrl = '';
    if (tabOptions?.length) {
      const defaultSelectedTab = tabOptions.find((t) => t.selected) || this.tabOptions[0];
      selectedTab = defaultSelectedTab?.id || tabOptions[0].id;
      selectedTabObject = defaultSelectedTab || tabOptions[0];
      this.$store.set('dashboard/selectedDashboardTab', selectedTab);

      selectedTabTitle = defaultSelectedTab?.value || tabOptions[0].value;
      if (isDemoInstance()) {
        selectedTabUrl = defaultSelectedTab?.demoViewUrl || tabOptions[0].demoViewUrl;
        selectedTabSubUrl = defaultSelectedTab?.demoViewSubUrl || tabOptions[0].demoViewSubUrl;
      } else {
        selectedTabUrl = defaultSelectedTab?.viewUrl || tabOptions[0].viewUrl;
        selectedTabSubUrl = defaultSelectedTab?.viewSubUrl || tabOptions[0].viewSubUrl;
        if (config.TABLEAU_URL === TABLEAU_PROD_URL) {
          selectedTabDataUrl = defaultSelectedTab?.dataUrlsProd || tabOptions[0].dataUrlsProd;
        } else {
          selectedTabDataUrl = defaultSelectedTab?.dataUrlsDev || tabOptions[0].dataUrlsDev;
        }
      }
    }
    return {
      tabOptions,
      selectedTab,
      selectedTabObject,
      selectedTabTitle,
      selectedTabUrl,
      selectedTabSubUrl,
      selectedTabDataUrl,
      MNI_REPORTING_TABLEAU_URL,
      tableauJwtToken: null,
      tableauError: false,
      sheets: [],
      selectedSheet: '',
      viz: null,
      container: 'tableau-viz',
      tableauRestToken: null,
      isTableauDashboardInitialized: false,
      isCachedDateLoaded: false,
      isMediaTypeOptionsInitialized: false,
      isCampaignOptionsInitialized: false,
      isAudienceOptionsInitialized: false,
      isCreativeOptionsInitialized: false,
      isEventOptionsInitialized: false,
      isComponentMounted: false,
      tableauDataParams: [],
      oldTableauDataParams: [],
      selectedTabHandlerType: 'tablueReportHandler',
      loading: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    filtersApplied: get('dashboard/filtersApplied'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    mniTemplateId: get('common/mniTemplateId'),
    currentUser: get('common/currentUser'),

    advertiserId() {
      return this.advertiser ? this.advertiser.xandrAdvertiserId : 0;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
    tableauViewURL() {
      let selectedTemplateId = this.advertiser?.templateId;
      selectedTemplateId =
        selectedTemplateId !== undefined || selectedTemplateId !== null
          ? selectedTemplateId
          : DEFAULT_MNI_ADVERTISER_TEMPLATE_MAPPING;
      const templateURL = `${config.TABLEAU_URL}${this.selectedTabUrl}`;
      let tableauViewURL = '';
      if (this.selectedTab === 'journeyanalytics') {
        tableauViewURL = `${templateURL}`;
      } else {
        const urlTemplId = isDemoInstance() ? '' : `-${selectedTemplateId}`;
        tableauViewURL = `${templateURL}${urlTemplId}${this.selectedTabSubUrl}${urlTemplId}`;
      }
      this.$store.dispatch('common/setMniTemplateId', selectedTemplateId);
      return tableauViewURL;
    },
    // below code return empty string when all items are selected sending empty string considered as all selected in tablue.
    selectedMediaTypeOptions() {
      const allChecked = this.mediaTypeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      return this.mediaTypeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.value)
        .join(',');
    },
    campaignOptionsValues() {
      const allChecked = this.campaignOptions.every((f) => f?.checked === true);
      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }
      const result = this.campaignOptions.filter((f) => f?.checked);
      const campaignIds =
        result
          .map((res) => res.key)
          .filter((fres) => fres)
          .join(',') || '';
      return campaignIds;
    },
    selectedAudienceOptions() {
      const allChecked = this.audienceOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedAudience = this.audienceOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedAudience;
    },
    updateConversionWindow() {
      return this.conversionWindow ? this.conversionWindow : DEFAULT_CONVERSION_WINDOW;
    },
    creativeOptionsOptions() {
      const allChecked = this.creativeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedCreative = this.creativeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedCreative;
    },
    eventFilters() {
      const selectedEvent = this.eventOptions.filter((event) => event.selected === true);
      let eventCategory = [];
      let eventSubcategory = [];

      if (selectedEvent.length > 0) {
        // Collect parent categories
        const category = selectedEvent.map((event) => event.category);
        eventCategory = ['~~null~', ...category];

        // Filter out events that have subcategories and are selected
        const eventsWithChilds = selectedEvent.filter(
          (event) => event.numSubCategories > 0 && event.children
        );

        // Collect subcategories
        const subcategory = eventsWithChilds.flatMap(
          (event) =>
            event.children
              .filter((subEve) => subEve.selected) // Filter based on the selected property
              .map((subEve) => subEve.category) // Map to return only the category
        );

        if (isBlank(subcategory)) {
          eventSubcategory = null;
        } else {
          eventSubcategory = ['~~null~', ...subcategory];
        }

        // Check if all parent and child items are selected
        const allParentsSelected = this.eventOptions.every((event) => event.selected === true);
        const allChildrenSelected = this.eventOptions.every((event) =>
          event.children ? event.children.every((child) => child.selected === true) : true
        );

        // If all items are selected, both arrays should be empty
        if (allParentsSelected && allChildrenSelected) {
          eventCategory = [];
          eventSubcategory = [];
        }
      }
      return {
        eventCategory,
        eventSubcategory,
      };
    },
  },
  watch: {
    advertiserId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) {
            await this.debounceTableauAuthToken();
          }
        }
      },
    },
    accountId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) {
            await this.debounceTableauAuthToken();
          }
        }
      },
    },
    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) {
            if (this.isCachedDateLoaded && Object.hasOwn(n, 'compareStartDate')) {
              await this.debounceTableauAuthToken();
            }
            if (!Object.hasOwn(n, 'compareStartDate')) {
              this.isCachedDateLoaded = true;
            }
            if (!this.isCachedDateLoaded && Object.hasOwn(n, 'compareStartDate')) {
              await this.debounceTableauAuthToken();
            }
          }
        }
      },
    },
    mediaTypeOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (this.isMediaTypeOptionsInitialized) {
            await this.debounceTableauAuthToken();
          }
          if (o.length) {
            this.isMediaTypeOptionsInitialized = true;
          }
        }
      },
    },
    conversionWindow: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n.toString(), o.toString())) {
          await this.debounceTableauAuthToken();
        }
      },
    },
    campaignOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o.length && n.length) {
            this.isCampaignOptionsInitialized = true;
          }
          if (this.isCampaignOptionsInitialized) {
            await this.debounceTableauAuthToken();
          }
        }
      },
    },
    audienceOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (this.isAudienceOptionsInitialized) {
            await this.debounceTableauAuthToken();
          }
          if (o.length) {
            this.isAudienceOptionsInitialized = true;
          }
        }
      },
    },
    creativeOptions: {
      immediate: false,
      async handler(o, n) {
        if (!_.isEqual(n, o)) {
          if (this.isCreativeOptionsInitialized) {
            await this.debounceTableauAuthToken();
          }
          if (o.length) {
            this.isCreativeOptionsInitialized = true;
          }
        }
      },
    },
    eventFilters: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (this.isEventOptionsInitialized) {
            await this.debounceTableauAuthToken();
          }
          if (n?.eventCategory?.length !== 0) {
            this.isEventOptionsInitialized = true;
          }
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
          if (n && this.tableauDataParams !== this.oldTableauDataParams) {
            await this.debounceTableauAuthToken();
          }
        }
      },
    },
    tableauJwtToken: {
      deep: true,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          const tableauComponent = document.getElementById('tableauViz');
          if (!o && !this.viz) {
            await this.initializeTableau(tableauComponent);
          }
          if (this.viz) {
            this.viz.addEventListener(TableauEventType.VizLoadError, this.handleTableauError);
            // this.viz.addEventListener('firstinteractive', () => {
            //   console.log('Tableau dashboard has finished loading!');
            // });
          }
        }
      },
    },
    // selectedTabTitle: {
    //   deep: true,
    //   async handler(n, o) {
    //     if (!_.isEqual(n, o)) {
    //       const userHasAiAssistant = checkUserHasAiAssistant(this.currentUser);
    //       if (userHasAiAssistant) await this.fetchAllTableauData();
    //     }
    //   },
    // },
  },
  async mounted() {
    this.loading = true;
    await this.initTableau();
  },
  methods: {
    async initTableau() {
      if (EventBus.isCampaignIdsReady && EventBus.eventsLoaded) {
        this.loading = true;
        await this.getTableauAuthToken(false);
        await this.fetchTableauRestApiAuthToken();
        this.selectedTabTitle = this.selectedTabTitle ? this.selectedTabTitle : 'Overview';
        const userHasAiAssistant = checkUserHasAiAssistant(this.currentUser);
        if (userHasAiAssistant) {
          await this.fetchAllTableauData();
          this.isComponentMounted = true;
        }
      } else {
        setTimeout(() => {
          // retrying as campaign ids are not yet ready...
          this.initTableau();
        }, 200);
      }
    },
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
      this.oldTableauDataParams = this.tableauDataParams;
    },
    async getTableauAuthToken(force = true, maxRetries = 3) {
      this.loading = true;
      // console.log('retries left: ', maxRetries);
      if (maxRetries < 1) return;
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau`, {
          withCredentials: true,
        })
        .then((d) => {
          this.tableauJwtToken = d.data.result;
          this.loading = false;
          if (d.status !== 200) {
            this.getTableauAuthToken(maxRetries - 1);
            this.loading = false;
          }
        });
      // every time auth token is debaunced we will call fetchAllTableauData() to get fresh data according to new filters criteria
      const userHasAiAssistant = checkUserHasAiAssistant(this.currentUser);
      if (userHasAiAssistant && force) await this.fetchAllTableauData();
    },
    checkIfDemoInstance() {
      return isDemoInstance();
    },
    onTabClick(tab) {
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.selectedTabHandlerType = tab.handler;
      this.selectedTabObject = tab;
      this.selectedTabTitle = tab.value;
      if (isDemoInstance()) {
        this.selectedTabUrl = tab.demoViewUrl;
        this.selectedTabSubUrl = tab.demoViewSubUrl;
      } else {
        this.selectedTabUrl = tab.viewUrl;
        this.selectedTabSubUrl = tab.viewSubUrl;
        this.selectedTabDataUrl = this.getTableauDataUrls();
      }
      this.$store.set('dashboard/selectedDashboardTab', tab.id);
      if (this.selectedTabHandlerType === 'incrementalityHandler') {
        this.$store.set('dashboard/filters@ioOptions', this.campaignOptions); // incrementality widget uses ioOptions to fetch data but IoOptions are remaining empty initialy.
      }
      this.debounceTableauAuthToken();
    },
    async initializeTableau(tableauComponent) {
      const viz = new TableauViz();

      viz.src = this.tableauViewURL;
      viz.token = this.tableauJwtToken;
      viz.toolbar = 'hidden';

      if (tableauComponent) {
        tableauComponent.appendChild(viz);
      }

      this.viz = viz;
      this.isTableauDashboardInitialized = true;
    },
    handleTableauError(e) {
      console.error('Error fetching Tableau: ', e);
      if (e.detail.errorCode === 'auth-failed') {
        this.debounceTableauAuthToken();
      }
    },
    async fetchTableauRestApiAuthToken() {
      await axios
        .get(`${config.ADREADY_URL}/api/token/tableau/sign/${this.advertiser.id}`, {
          withCredentials: true,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': true,
        })
        .then((d) => {
          if (d.data.result) {
            this.tableauRestToken = d.data.result;
          }
          return d;
        });
    },
    async fetchAllTableauData() {
      if (!this.isTableauDashboardInitialized) return;
      await this.selectedTabDataUrl.forEach((item) => {
        this.fetchTableauData(item.dataUrl);
      });
    },
    async fetchTableauData(dataUrl) {
      if (!this.tableauRestToken) return {};
      const params = this.createTableauDataParams();
      const url = config.TABLEAU_URL + dataUrl + params;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-Tableau-Auth': this.tableauRestToken,
          },
        });
        return response;
      } catch (err) {
        console.error('error in fetchTableauData ->', err, this.advertiser.id);
        return {};
      }
    },
    getTableauDataUrls() {
      let selectedTabDataUrl = [];
      if (config.TABLEAU_URL === TABLEAU_PROD_URL) {
        selectedTabDataUrl = this.selectedTabObject.dataUrlsProd;
      } else {
        selectedTabDataUrl = this.selectedTabObject.dataUrlsDev;
      }
      return selectedTabDataUrl;
    },
    createTableauDataParams() {
      let params = '';
      const advertiserParam = `?vf_Advertiser=${this.advertiserId ? this.advertiserId : ''}`;
      const startDateParam = `&vf_Start+Date=${this.startDate ? this.startDate : ''}`;
      const endDateParam = `&vf_End+Date=${this.endDate ? this.endDate : ''}`;
      const daysToConverParam = `&vf_Days+To+Convert+Parameter=${
        this.updateConversionWindow ? this.updateConversionWindow : ''
      }`;
      const eventCategoryParam = `&vf_Event+Category=${
        this.eventFilters.eventCategory ? this.eventFilters.eventCategory : ''
      }`;
      const eventSubCategoryParam = `&vf_Event+Subcategory=${
        this.eventFilters.eventSubcategory ? this.eventFilters.eventSubcategory : ''
      }`;
      const mediaTypeParam = `&vf_Media+Type=${
        this.selectedMediaTypeOptions ? this.selectedMediaTypeOptions : ''
      }`;
      const campaignIdParam = `&vf_Campaign+ID=${
        this.campaignOptionsValues ? this.campaignOptionsValues : ''
      }`;
      const adreadyIdParam = `&vf_AdReady+ID=${
        this.selectedAudienceOptions ? this.selectedAudienceOptions : ''
      }`;
      const creativeIdParam = `&vf_Creative+ID=${
        this.creativeOptionsOptions ? this.creativeOptionsOptions : ''
      }`;
      params =
        advertiserParam +
        startDateParam +
        endDateParam +
        daysToConverParam +
        eventCategoryParam +
        eventSubCategoryParam +
        mediaTypeParam +
        campaignIdParam +
        adreadyIdParam +
        creativeIdParam;

      this.tableauDataParams = params;
      return this.tableauDataParams;
    },
  },
};
</script>
<style lang="scss" scoped>
.bdc_main_wrap.whero {
  margin-top: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}
@media (max-width: 750px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
}
.chart-sec {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
